import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const Button = (props) => {
  const useStyles = makeStyles((theme) => ({
    zone: {
      flex: 1,
      borderRadius: 30,
      paddingTop: 5,
      paddingBottom: 5,
      paddingRight: 20,
      paddingLeft: 20,
      backgroundColor: props.color,
      textAlign: 'center',
      borderWidth: 0,
      cursor: 'pointer',
      "&:hover": {
        borderStyle: 'none',
        opacity: '0.75'
      },
      "&:focus": {
        outline: 'none'
      },
      "&:active": {
      }
    },
    label: {
      color: '#f2f2f2',
      fontSize: '20px',
      fontFamily: 'Montserrat',
      fontWeight: '500'
    }
  }));
  const classes = useStyles();

  return (
    <button className={classes.zone}>
      <Typography className={classes.label}>{props.label}</Typography>
    </button>
  );
}

export default Button;

