import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Grid, Typography} from '@material-ui/core'
import Fade from 'react-reveal/Fade'
import {FlipCard} from "../../components/flip-card/flip-card";
import Text from "../../components/texts/Text";

const useStyles = makeStyles((theme) => ({
  zone: {
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: '5%',
    paddingRight: '5%',
    flex: 1,
    backgroundColor: '#1a1919'
  },
  titleZone: {
    float: 'right',
    marginBottom: 50,
    marginRight: 100
  }
}));

//<Card color={treatment.color} title={treatment.title} icon={treatment.icon} text={treatment.text} />

const treatments = [
  { title: "Odontoiatria Conservativa", color: "#5856D6", type: 'dentalCare', path: "dental-care", text: `La conservativa è quella parte dell’Odontoiatria restaurativa che si occupa della prevenzione e della terapia delle lesioni dentali. Il paziente puo' presentare malformazioni dentarie, carie o lesioni traumatiche. Questi problemi possono essere risolti con un trattamento restaurativo.`},
  { title: "Endodonzia", color: "#ff5b03", type: 'endodonthic', path: "endodonthic", text: `La endodonzia è un trattamento infocato alla conservazione del dente naturale. Quotidianamente si svolge questa tecnica giacché le carie sono molto frequenti nelle bocche delle persone. Tale trattamento consiste nell’eliminare l’infezione batteriana  che è giunta alla polpa del dente e che causa dolore al paziente. ` },
  { title: "Trattamenti Infantili", color: "#ff2d55", type: 'childTreatment', path: "child-treatment", text: `Il principale obbiettivo dell’odontoiatria infantile è di stabilire fin dall’inizio  una corretta posizione e dimensione delle ossa, correggendo in tempo problemi bucodentali futuri.` },
  { title: "Chirurgia Orale", color: "#ffcc22", type: 'oralSurgery', path: "oral-surgery", text: `La chirurgia orale è quella rama dell’odontoiatria che si occupa di tutta una serie di interventi chirurgici che possono essere effettuati all’interno del cavo orale e che possono riguardare diverse problematiche.` },
  { title: "Protesi", color: "#31ba54", type: 'prosthesis', path: "prosthesis", text: `Le protesi dentarie servono a riabilitare le funzioni orali dei pazienti affetti da edentulia, cioè  mancanza di denti, paziale o totale, mediante la sostituzione dei denti naturali con elementi dentali artificiali. ` },
  { title: "Implantologia", color: "#5bc7fa", type: 'implantology', path: "implantology", text: `L’implantologia dentale è quella rama dell’odontoiatria che si occupa di risolvere e ristabilire le radici dentarie mancanti. Un impianto dentale è una radice artificiale in titanio che si colloca nell’osso ed è capace di integrarsi intimamente con i tessuti della bocca.` },
  { title: "Sbiancamento Dentale", color: "#af52de", type: 'whitening', path: "whitening", text: `Lo sbiancamento dentale è uno dei trattamenti più richiesti dai nostri pazienti. Il procedimento è semplice e si realizza in un’unica seduta. Il risultato sarà immediato. 
Questo trattamento ti permetterà di sfoggiare un sorriso smagliante e attrattivo.` },
  { title: "Ortodonzia", color: "#007aff", type: 'orthodontic', path: "orthodontic", text: `L’ortodonzia è quella  rama dell’ odontoiatria che si occupa di ristabilire la giusta posizione dei denti e migliorare la struttura ossea facciale. Tale trattamento ingloba un’attenta analisi e studio del caso, radiografie intraorali  e visite mensili in sede.` },
  { title: "Acido Ialuronico", color: "#fc08d3", type: 'lips', path: "lips", text: `Il filler alle labbra è una delle tecniche più richieste oggigiorno in medicina estetica. Questo trattamento apporta volume e idratazione alle labbra e rende più armonioso il volto della persona.` },
  { title: "Trattamenti anti-age: Radiofrequenza", color: "#30de91", type: 'facialTreatment', path: "facial-treatment", text: `La radiofrequenza è una tecnica di medicina estetica indolore e non invasiva molto efficace per levigare la pelle e rassodare i tessuti del viso e del collo. ` }
];

const Treatments = () => {
  const classes = useStyles();

  return (
    <div className={classes.zone}>
      <Fade right duration='2000' >
        <div className={classes.titleZone}>
          <Text size="large" weight="700" color="color1" content="I trattamenti a misura per te" />
        </div>
      </Fade>
      <Grid container spacing={3}>
        {treatments.map((treatment,index) => (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3} spacing={6}>
            <Fade bottom duration={2000} delay={0+(200*index)}>
              <FlipCard color={treatment.color} title={treatment.title} type={treatment.type} text={treatment.text} />
            </Fade>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Treatments;
