import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Grid, Typography} from '@material-ui/core'
import Fade from 'react-reveal/Fade'
import partnershipsImage from '../../images/partnerships.png'
import dentistEquipmentsImage from '../../images/dentist-equipments.png'
import Text from "../../components/texts/Text"

const MD_MIN_WIDTH = 960;

const useStyles = makeStyles((theme) => ({
  titleZone: {
    float: 'left',
    marginLeft: -35,
    marginBottom: 80
  },
  zone: {
    marginBottom: 100,
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 50,
      paddingRight: 50
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 50,
      paddingRight: 50
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 60,
      paddingRight: 60
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 80,
      paddingRight: 80
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '10%',
      paddingRight: '10%'
    },
    flex: 1,
    textAlign: 'center',
    justifyContent: 'center'
  },
  zoneWrapper: {
    paddingTop: 50,
    paddingLeft: '10%',
    paddingRight: '10%',
    flex: 1
  },
  partnershipImage: {
    width: '100%',
  },
  text: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    color: '#1a1919',
    fontWeight: '500'
  },
  bottomText: {
    marginBottom: 30
  }
}));

const Services = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.zoneWrapper}>
      <Fade left duration='2000'>
        <div className={classes.titleZone}>
          <Text size="large" weight="700" color="color2" content="Servizi di alta qualità" />
        </div>
      </Fade>
      <div className={classes.zone}>
        <Grid container spacing={0} justify="center" alignItems="center">
          <Grid container justify="center" alignItems="center" spacing={10}>
            <Grid container justify="center" alignItems="center" spacing={10}>
              <Grid item xs={12} sm={12} md={6} lg={5} xl={5} style={{order: props.dimensions.width < MD_MIN_WIDTH ? 2 : 1 }}>
                <Fade duration='3500'>
                  <div className={[classes.bottomText]}>
                    <Text size="xxSmall" color="color2" content="Collaboriamo con un laboratorio 100% italiano che ci permette di portare a termine lavori di
                    vera eccellenza. Abbiamo la possibilità di lavorare fianco a fianco con il tecnico protesico che grazie
                    al suo team riesce a realizzare lavori di vero prestigio." />
                  </div>
                  <Text size="xxSmall" color="color2" content="Nella clinica Zamora cerchiamo sempre di puntare sulla qualità dei nostri macchinari, offriamo modelli
                    di ultima generazione che ci permettono di dare una dose minima nell’esposizione di radiazione." />
                </Fade>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={7} xl={7} style={{ paddingBottom: 0, order: props.dimensions.width < MD_MIN_WIDTH ? 1 : 2 }}>
                <img src={dentistEquipmentsImage} alt="Logo" className={classes.partnershipImage}/>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={7} xl={7} style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
              <img src={partnershipsImage} alt="Logo" className={ classes.partnershipImage} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
              <Fade duration='3500'>
                <div className={[classes.bottomText]}>
                  <Text size="xxSmall" color="color2" content="Pensiamo che alla base del successo  di un trattamento ci sia la qualità del prodotto e per questo
                  motivo vi mostriamo alcune delle imprese con cui lavoriamo." />
                </div>
                <Text size="xxSmall" color="color2" content="I risultati si vedono nelle bocche dei nostri pazienti." />
              </Fade>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Services;

