import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Grid, Toolbar, Typography} from '@material-ui/core'
import ReactCardFlip from 'react-card-flip'
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom"
import Button from '../button'

import brackets from "../../icons/dental/brackets.svg"
import dentalCare from "../../icons/dental/dental-care.svg"
import facialTreatment from "../../icons/dental/facial-treatment.svg"
import implant from "../../icons/dental/implant.svg"
import lips from "../../icons/dental/lips.svg"
import oralSurgery from "../../icons/dental/oral-surgery.svg"
import rootCanal from "../../icons/dental/root-canal.svg"
import whitening from "../../icons/dental/whitening.svg"
import toothFairy from "../../icons/dental/tooth-fairy.svg"
import crown from "../../icons/dental/crown.svg"

import lipsImage from "../../images/treatments/lips.png"
import oralSurgeryImage from "../../images/treatments/oral-surgery.png"
import orthodonticImage from "../../images/treatments/orthodontic.png"
import prosthesisImage from "../../images/treatments/prosthesis.png"
import childTreatmentImage from "../../images/treatments/child-treatment.png"
import implantologyImage from "../../images/treatments/implantology.png"
import dentalCareImage from "../../images/treatments/dentalCare.png"
import facialTreatmentImage from "../../images/treatments/facialTreatment.png"
import endodonthicImage from "../../images/treatments/endodonthic.png"
import whiteningImage from "../../images/treatments/whitening.png"
import {device} from "../../utils/deviceType";
import dentistEquipmentsImage from "../../images/dentist-equipments.png";
import partnershipsImage from "../../images/partnerships.png";

const HEADER_HEIGHT = 70;
const CARD_HEIGHT = 420;
const LEFT_LINE = 5;
const PADDING_TEXT_ZONE = 15;
const PADDING_LEFT_TEXT_ZONE = LEFT_LINE + PADDING_TEXT_ZONE;

const deviceType = device();

export const FlipCard = (props) => {
  const [isFlipped, setIsFlipped] = useState(false);

  /* Loading the good icon */
  let image;
  switch (props.type) {
    case 'orthodontic':
      image = orthodonticImage;
      break;
    case 'childTreatment':
      image = childTreatmentImage;
      break;
    case 'prosthesis':
      image = prosthesisImage;
      break;
    case 'dentalCare':
      image = dentalCareImage;
      break;
    case 'facialTreatment':
      image = facialTreatmentImage;
      break;
    case 'implantology':
      image = implantologyImage;
      break;
    case 'lips':
      image = lipsImage;
      break;
    case 'oralSurgery':
      image = oralSurgeryImage;
      break;
    case 'endodonthic':
      image = endodonthicImage;
      break;
    case 'whitening':
      image = whiteningImage;
      break;
  }

  /* Handle style */
  const useStyles = makeStyles((theme) => ({
    rearZone: {
      height: CARD_HEIGHT,
      backgroundColor: '#f2f2f2',
      borderRadius: '15px',
      borderLeft: `${LEFT_LINE}px solid ${props.color}`
    },
    rearTextZone: {
      paddingRight: PADDING_TEXT_ZONE,
      paddingLeft: PADDING_LEFT_TEXT_ZONE,
      paddingTop: PADDING_TEXT_ZONE,
      paddingBottom: PADDING_TEXT_ZONE
    },
    frontalZoneWrapper:  {
      height: CARD_HEIGHT,
      borderRadius: 15,
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden'
    },
    frontalZone:  {
      height: CARD_HEIGHT,
      borderRadius: 11,
      backgroundImage: `url(${image})`,
      backgroundPosition  : 'center',
      backgroundRepeat  : 'no-repeat',
      flex: 1,
      resizeMode: 'cover',
      filter: 'grayscale(85%) contrast(120%)'
    },
    header: {
      marginBottom: 10,
      textAlign: 'center'
    },
    icon: {
      height: HEADER_HEIGHT,
      width: HEADER_HEIGHT
    },
    title: {
      color: "#f2f2f2",
      fontSize: '30px',
      lineHeight: 1.2,
      fontFamily: 'Montserrat',
      fontWeight: '600',
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      top: '50%',
      transform: 'translateY(-50%)'
    },
    filter: {
      color: props.color,
      fontSize: '30px',
      lineHeight: 1.2,
      fontFamily: 'Montserrat',
      fontWeight: '600',
      position: 'absolute',
      width:  `calc(100%)`,
      height: '100%',
      textAlign: 'center',
      top: 0,
      left: 0,
      borderRadius: 11,
      backgroundColor: '#1a1919',
      opacity: '0.65'
    },
    text: {
      color: '#1a1919',
      fontSize: 20,
      fontFamily: 'Montserrat',
      fontWeight: '500'
    },
    button: {
      position: 'absolute',
      textAlign: 'center',
      width: `calc(100% - ${PADDING_LEFT_TEXT_ZONE + PADDING_TEXT_ZONE + LEFT_LINE}px)`,
      bottom: PADDING_TEXT_ZONE
    }
  }));
  const classes = useStyles();

  /* Loading the good icon */
  let Icon;
  switch (props.type) {
    case 'childTreatment':
      Icon = <embed src={toothFairy} className={classes.icon} />;
      break;
    case 'dentalCare':
      Icon = <embed src={dentalCare} className={classes.icon} />;
      break;
    case 'endodonthic':
      Icon = <embed src={rootCanal} className={classes.icon} />;
      break;
    case 'facialTreatment':
      Icon = <embed src={facialTreatment} className={classes.icon} />;
      break;
    case 'implantology':
      Icon = <embed src={implant} className={classes.icon} />;
      break;
    case 'lips':
      Icon = <embed src={lips} className={classes.icon} />;
      image = lipsImage;
      break;
    case 'oralSurgery':
      Icon = <embed src={oralSurgery} className={classes.icon} />;
      break;
    case 'orthodontic':
      Icon = <embed src={brackets} className={classes.icon} />;
      break;
    case 'prosthesis':
      Icon = <embed src={crown} className={classes.icon} />;
      break;
    case 'whitening':
      Icon = <embed src={whitening} className={classes.icon} />;
      break;
  }

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  }

  const handleFlipOnClick = () => {
    setIsFlipped(!isFlipped);
  }

  return (
    <div>
    <div
      onMouseEnter={ () => handleFlipOnClick() }
      onMouseLeave={ () => handleFlipOnClick() }
    >
      <ReactCardFlip
        isFlipped={isFlipped}
        flipSpeedBackToFront={2}
        flipSpeedFrontToBack={2}
      >
        <div className={classes.frontalZoneWrapper}>
          <div className={classes.frontalZone} />
          <div className={classes.filter} />
          <Typography className={classes.title}>{props.title}</Typography>
        </div>

        <div className={classes.rearZone}>
          <div className={classes.rearTextZone}>
            <div className={classes.header}>
              {Icon}
            </div>
            <Typography className={classes.text}>{props.text}</Typography>
            <div className={classes.button}>
              <Button label='Scopri di più' color={props.color} />
            </div>
          </div>
        </div>
      </ReactCardFlip>
    </div>

    </div>
  )
}
