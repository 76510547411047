import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Grid, Typography} from '@material-ui/core'
import Fade from 'react-reveal/Fade'
import Text from "../components/texts/Text"

const useStyles = makeStyles((theme) => ({
  zone: {
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: '5%',
    paddingRight: '5%',
    flex: 1,
    backgroundColor: '#1a1919'
  },
  titleZone: {
    float: 'right',
    marginBottom: 50,
    marginRight: 100
  }
}));

const FacialTreatment = () => {
  const classes = useStyles();

  return (
    <div className={classes.zone}>
      <div className={classes.titleZone}>
        <Text size="large" weight="700" color="color1" content="I trattamenti a misura per te" />
      </div>
    </div>
  );
}

export default FacialTreatment;