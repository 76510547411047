import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Fade, Grid, Toolbar, Typography } from '@material-ui/core'
import Text from "../../components/texts/Text"
import notFound from "../../images/errors/not-found.svg";

const Home = () => {

  const useStyles = makeStyles((theme) => ({
    zone: {
      height: '100%',
      position: 'absolute',
      left: 0,
      width: '100%',
      overflow: 'hidden',
      backgroundColor: '#1a1919'
      //08b3b3
    },
    contentZone: {
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      top: '50%',
      transform: 'translateY(-50%)'
    },
    contentTextZone: {
      marginTop: 50
    },
    image: {
      width: 400
    }
  }));

  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  return (
    <div className={classes.zone}>
      <div className={classes.contentZone}>
        <embed src={notFound} className={classes.image}/>
        <div className={classes.contentTextZone}>
          <Text size="small" weight="700" color="color1" content="Pagina Non Trovata" />
          <Text size="xxSmall" color="color1" content="Oops ! La pagina che cerchi non è stata trovata." />
        </div>
      </div>
    </div>
  );
}

export default Home;

