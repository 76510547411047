import React from 'react';
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {device} from "../../utils/deviceType"

const Text = (props)=> {
  const deviceType = device();
  let size = 30;
  let color = '#f6f6f6';
  let weight = '500';
  let lineHeight;

  if(deviceType == "web" || deviceType == "tablet" ) {
    // Handle size
    switch (props.size) {
      case 'xLarge':
        size = 70;
        lineHeight = 1.2;
        break;
      case 'large':
        size = 50;
        lineHeight = 1.2;
        break;
      case 'normal':
        size = 40;
        lineHeight = 1.2;
        break;
      case 'small':
        size = 30;
        break;
      case 'xSmall':
        size = 26;
        break;
      case 'xxSmall':
        size = 20;
        break;
      default:
        size = 30;
    }
  }

  if(deviceType == "mobile") {
    // Handle size
    switch (props.size) {
      case 'xLarge':
        size = 50;
        lineHeight = 1.2;
        break;
      case 'large':
        size = 30;
        lineHeight = 1.2;
        break;
      case 'normal':
        size = 26;
        lineHeight = 1.2;
        break;
      case 'small':
        size = 24;
        break;
      case 'xSmall':
        size = 20;
        break;
      case 'xxSmall':
        size = 18;
        break;
      default:
        size = 26;
    }
  }

  // Handle color
  switch (props.color) {
    case 'color1':
      color = '#f6f6f6';
      break;
    case 'color2':
      color = '#1a1919';
      break;
    case 'red':
      color = '#ff2d55';
      break;
    default:
      color = '#f6f6f6';
  }

  // Handle weight
  weight = props.weight;

  const useStyles = makeStyles(() => ({
    text: {
      fontSize: size,
      color: color,
      fontFamily: 'Montserrat',
      fontWeight: weight,
      wordWrap: 'break-word',
      lineHeight: lineHeight
    }
  }));

  const classes = useStyles();

  return (
    <Typography className={[classes.text]}>
      {props.content}
    </Typography>
  );
}

export default Text;