import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Fade, Grid, Toolbar, Typography } from '@material-ui/core'
import LazyLoad from 'react-lazyload'
import logo from '../../images/logo.svg'
import background from '../../images/background.png'
import { TOP_BAR_HEIGHT } from '../../utils/global'
import Text from '../../components/texts/Text'
import Team from './Team'
import Quotes from './Quotes'
import Treatments from './Treatments'
import Info from './Info'
import Services from './Services'
import { device } from "../../utils/deviceType"
import { Helmet } from "react-helmet"


function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

const deviceType = device();



/*
let background;
if(deviceType == "mobile") {
  background = require('../../images/background.png');
}
else {
  background = require('../../images/background.png');
}*/

const Home = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 1000)


    if(deviceType == "web") {
      window.addEventListener('resize', debouncedHandleResize);
    }

    setTimeout(() => {
      setChecked(true); // count is 0 here
    }, 700);

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }


  })

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    paper: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    app: {
      overflow: 'hidden',
      backgroundColor: '#f2f2f2'
    },
    topBar: {
      height: TOP_BAR_HEIGHT,
      opacity:'99%',
      backgroundColor: '#1a1919',
      justifyContent: 'center',
      paddingRight: '30px',
      paddingLeft: '30px'
    },
    toolBar: {
      minHeight: '50px',
    },
    topBarLogo: {
      margin: 0,
      height: TOP_BAR_HEIGHT,
      width: TOP_BAR_HEIGHT
    },
    unitWrapper: {
      position: 'relative'
    },
    welcomeZone: {
      height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
      marginTop: TOP_BAR_HEIGHT,
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      alignSelf: 'stretch'
    },
    welcomeZoneFilter: {
      lineHeight: 1.2,
      fontFamily: 'Montserrat',
      fontWeight: '600',
      position: 'absolute',
      width:  `100%`,
      height: '100%',
      textAlign: 'center',
      top: 0,
      backgroundColor: '#1a1919',
      opacity: '0.75'
    },
    welcomeContent: {
      paddingBottom: '40px',
      textAlign: 'center'
    },
    welcomeTitleZone: {
      paddingTop: TOP_BAR_HEIGHT + 30,
      marginBottom: 0
    },
    welcomeSubTitleZone: {
      marginBottom: 70
    },
    appContentTextZone: {
      wordWrap: 'break-word',
      marginLeft: '20%',
      float:'left'
    }
  }));

  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  return (
    <div className={classes.app}>
      <AppBar className={classes.topBar}>
        <Toolbar className={classes.toolBar}>
          <embed src={logo} className={classes.topBarLogo}/>
        </Toolbar>
      </AppBar>
        <Grid container spacing={0}>
          <Helmet>
            <title>Studio Dentistico Zamora | Home</title>
            <meta name="description" content="Da molti anni nello Studio Dentistico Zamora ci occupiamo di voi e del vostro sorriso. Da noi potete realizzare ogni tipo di trattamento dentale di alta qualità..." />
            <link rel="canonical" href="https://studiodentisticozamora.com" />
          </Helmet>

          <Grid item xs className={classes.unitWrapper}>
            <div className={classes.welcomeZone}>
              <div className={classes.welcomeZoneFilter}>

                <div className={classes.welcomeContent}>
                  <Fade timeout={2500} in={true}>
                    <div className={classes.welcomeTitleZone}>
                        <Text size="xLarge" content="Studio Dentistico Zamora" />
                    </div>

                  </Fade>
                  <Fade timeout={2500} in={checked} >
                    <div className={classes.welcomeSubTitleZone}>
                      <Text size="normal" content="Enjoy your smile" />
                    </div>
                  </Fade>

                  {(deviceType == "web" && dimensions.width >= 600 ?
                    <Grid container spacing={3}>
                      <Grid item xs={9} style={{textAlign: "left"}}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <div className={classes.appContentTextZone}>
                              <Text size="small" content="Il sorriso è un diritto di tutti e vedervi felici è la nostra priorità. Ormai sono molti anni che ci occupiamo di voi e della vostra bocca. La nostra passione è il nostro lavoro." />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    : null
                  )}

                </div>
              </div>
            </div>
          </Grid>
        </Grid>

      <Team />
      <Treatments />
      <Services dimensions={dimensions}/>
      <Quotes />
      <Info dimensions={dimensions}/>
    </div>
  );
}

export default Home;

