import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import Fade from 'react-reveal/Fade'
import '../../App.css';
import ElizabethZamoraPhoto from '../../images/team/drssa-elizabeth-zamora.png'
import KathelineOlabarreraPhoto from '../../images/team/drssa-katheline-olabarrera.png'
import Text from '../../components/texts/Text'
import Tooltip from '@mui/material/Tooltip'

import { ReactComponent as Brackets } from "../../icons/dental/brackets.svg"
import { ReactComponent as Implant } from "../../icons/dental/implant.svg"
import { ReactComponent as Lips } from "../../icons/dental/lips.svg"
import { ReactComponent as RootCanal } from "../../icons/dental/root-canal.svg"
import { ReactComponent as Whitening } from "../../icons/dental/whitening.svg"
import { ReactComponent as Crown } from "../../icons/dental/crown.svg"

const LIGTH_COLOR = '#f6f6f6';

const useStyles = makeStyles((theme) => ({
  zone: {
    paddingTop: 50,
    paddingBottom: 50,
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 15,
      paddingRight: 15
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '15%',
      paddingRight: '15%'
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 60,
      paddingRight: 60
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '10%',
      paddingRight: '10%'
    },
    textAlign: 'center',
    justifyContent: 'center'
  },
  titleZone: {
    float: 'left',
    marginBottom: 40
  },
  contentZone: {
    textAlign: 'center'
  },
  contentTitleZone: {
    marginBottom: 20
  },
  contentTextZone: {
    marginBottom: 10
  },
  card: {
    display: 'flex',
    flexDirection: 'row'
  },
  frontCard: {
    width: '85%',
    height: 450,
    backgroundColor: '#1a1919',
    zIndex: 1,
    padding: 15,
    alignItems: 'center'
  },
  backCard: {
    width: '85%',
    height: 450,
    backgroundColor: 'rgba(41,38,38,0.97)',
    [theme.breakpoints.up('xs')]: {
      marginTop: '5%',
      marginLeft: '-86.5%',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '5%',
      marginLeft: '-86.5%',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '5%',
      marginLeft: '-85%',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '5%',
      marginLeft: '-85%',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '5%',
      marginLeft: '-86.5%',
    },
    padding: 15
  },
  cardHeader: {
    width: '100%',
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 15
  },
  photoZone: {
    display: 'block',
    height: 250,
    width: 250,
    borderRadius: 300,
    backgroundColor: 'rgba(41,38,38,0.97)'
  },
  photo: {
    height: 250,
    borderRadius: 65,
    pointerEvents: 'none'
  },
  specialities: {
    marginTop: 35,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  specialityIconZone: {
    [theme.breakpoints.up('xs')]: {
      width: 35,
      height: 35,
      marginLeft: 8,
      marginRight: 8
    },
    [theme.breakpoints.up('sm')]: {
      width: 40,
      height: 40,
      marginLeft: 10,
      marginRight: 10
    },
    padding: 10,
    borderRadius: 100,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  specialityIcon: {
    width: '100%',
    height: '100%',
    fill: LIGTH_COLOR,
    stroke: LIGTH_COLOR
  }
}));

const Team = () => {
  const classes = useStyles();

  const team = [
    {
      position: "Dr.ssa",
      name: "Elizabeth Z.",
      photo: ElizabethZamoraPhoto,
      specialities: [
        {
          label: "Implantologia",
          color: "#5bc7fa",
          icon: <Implant className={classes.specialityIcon}/>
        },
        {
          label: "Protesi", 
          color: "#31ba54", 
          icon: <Crown className={classes.specialityIcon}/>
        },
        {
          label: "Endodonzia",
          color: "#ff5b03",
          icon: <RootCanal className={classes.specialityIcon}/>
        },
        {
          label: "Ortodonzia",
          color: "#007aff",
          icon: <Brackets className={classes.specialityIcon}/>
        }
      ]
    },
    {
      position: "Dr.ssa",
      name: "Katheline O.",
      photo: KathelineOlabarreraPhoto,
      specialities: [
        {
          label: "Estetica Dentale",
          color: "#af52de",
          icon: <Whitening className={classes.specialityIcon}/>
        },
        {
          label: "Implantologia",
          color: "#5bc7fa",
          icon: <Implant className={classes.specialityIcon}/>
        },
        {
          label: "Acido Ialuronico",
          color: "#fc08d3",
          icon: <Lips className={classes.specialityIcon}/>
        },
        {
          label: "Ortodonzia",
          color: "#007aff",
          icon: <Brackets className={classes.specialityIcon}/>
        }
      ]
    }
  ]

  return (
    <div className={classes.zone}>
      <div className={classes.titleZone}>
        <Fade left duration='2000' >
          <Text size="large" weight="700" color="color2" content="La nostra equipe" />
        </Fade>
      </div>

      <Grid container spacing={5} className={classes.contentZone} justify="center">
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.card}>
          <Fade left duration='2000' delay='500' exit>
            <div className={classes.frontCard}>
              <div className={classes.cardHeader}>
                <div className={classes.photoZone}>
                  <img src={team[0].photo} className={classes.photo} alt="" />
                </div>
              </div>
              <Text size="small" weight="700" color="color1" content={team[0].position + ' ' +team[0].name} />
                <div className={classes.specialities}>
                  {team[0].specialities.map((speciality,index) => (
                    <Tooltip title={speciality.label}>
                      <div className={classes.specialityIconZone} style={{ backgroundColor: speciality.color}}>
                        {speciality.icon}
                      </div>
                    </Tooltip>
                  ))}
                </div>
            </div>
            <div className={classes.backCard} />
          </Fade>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.card}>
          <Fade right duration='2000' delay='500'>
            <div className={classes.frontCard}>
              <div className={classes.cardHeader}>
                <div className={classes.photoZone}>
                  <img src={team[1].photo} className={classes.photo} alt="" />
                </div>
              </div>
              <Text size="small" weight="700" color="color1" content={team[1].position + ' ' + team[1].name} />
                <div className={classes.specialities}>
                  {team[1].specialities.map((speciality,index) => (
                    <Tooltip title={speciality.label}>
                      <div className={classes.specialityIconZone} style={{ backgroundColor: speciality.color}}>
                        {speciality.icon}
                      </div>
                    </Tooltip>
                  ))}
                </div>
            </div>
            <div className={classes.backCard} />
          </Fade>
        </Grid>
      </Grid>
    </div>
  );
}

export default Team;